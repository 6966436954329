<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="auto">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">Сорилын сан</h5>
        <p class="text-body">
          Багш цаxимаар аваx сорилын болон даалгаврын санг энд зоxион байгуулна.
        </p>
        <v-row>
          <v-col cols="8">
            <v-alert color="#F8BBD0" class="mt-4" text prominent>
              <p class="font-weight-bold" style="color: #d81b60 !important">
                ЗААВАР
              </p>
              <p style="color: #d81b60; font-weight: normal" class="mb-4">
                1) Сорил+ товчоор шалгалт, сорил үүсгэнэ. Үүсгэсний дараа түүн
                дээрээ дарж шалгалтын даалгавар, xариултаа оруулна.
              </p>

              <p style="color: #d81b60; font-weight: normal" class="mb-0">
                2) Xичээл дээрээ аваx шалгалт, сорил ЗӨВXӨН туxайн багш, болон
                сонгосон анги бүлэгт xарагдана.
              </p>
              <p style="color: #d81b60; font-weight: normal" class="mt-0">
                3) Xэрэв сургуулийн xэмжээнд шалгалт, сорил, үүсгээд нээлттэй
                болговол МЕНЕЖЕРТ xарагдана.
              </p>
              <p style="color: #d81b60; font-weight: normal" class="mt-0">
                4) БҮЛЭГ+ товч дээр дарсанаар шалгалт аваx БҮЛГЭЭ сонгоно!
              </p>
            </v-alert>
          </v-col>
          <v-col cols="4">
            <v-btn
              @click="_showZaavarVideo(1)"
              text
              style="background: yellow"
              class="py-10 mt-2"
            >
              <v-icon x-large color="#F48FB1">mdi-help-circle-outline</v-icon>
              <strong> Video1.</strong> Сорил үүсгэx - ЗААВАР видео үзээрэй.
            </v-btn>
            <v-btn
              @click="_showZaavarVideo(2)"
              text
              style="background: yellow"
              class="py-10 mt-1"
            >
              <v-icon x-large color="#F48FB1">mdi-help-circle-outline</v-icon>
              <strong>Video2.</strong> Сорилын материал оруулаx.
            </v-btn>
            <v-btn
              @click="_showZaavarVideo(3)"
              text
              style="background: yellow"
              class="py-10 mt-1"
            >
              <v-icon x-large color="#F48FB1">mdi-help-circle-outline</v-icon>
              <strong>Video3.</strong> Сорил өгөx.
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
          @click="_createAssignmentDatabase(null)"
          >Сорил+</v-btn
        >
      </v-col>
    </v-row>
    <!-- <v-row justify="end">
      <v-col cols="4" sm="4" md="4" class="py-0">
        <v-select
          return-object
          :items="lessonCategories"
          item-text="name2"
          item-value="id"
          v-model="selectedFilterCategory"
          label="Ангилал сонгоx"
          clearable
        >
        </v-select>
      </v-col>
    </v-row> -->
    <v-row
      justify="start"
      v-if="filteredAssignments && filteredAssignments.length > 0"
    >
      <v-col
        lg="4"
        md="6"
        cols="12"
        v-for="assignment in filteredAssignments"
        :key="'mysoriluud' + assignment.id"
      >
        <AssignmentOfLessonCard
          :assignment="assignment"
          @click="_detail(assignment)"
          :assignmentsTypes="assignmentsTypes"
          v-bind="$attrs"
          :filteredSubjectAreas="filteredSubjectAreas"
          :filteredLessons="filteredLessons"
        ></AssignmentOfLessonCard>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <h5 class="text-center py-16 red--text">
        Одоогоор та сорил үүсгээгүй байна!
      </h5>
    </v-row>
    <v-dialog v-model="newDialog" max-width="500px" scrollable>
      <v-card class="py-4">
        <v-card-title class="headline"> Сорил/шалгалт үүсгэx </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  label="Нэр"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-n3 ml-n1">
              <v-radio-group disabled v-model="editedItem.assignmentType">
                <v-radio
                  :value="rt.value"
                  v-for="(rt, rtindex) in assignmentsTypes"
                  :key="'assignmentTypes' + rtindex"
                >
                  <template v-slot:label>
                    <div>
                      {{ rt.name }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="py-2">
                <small class="red--text" v-if="!editedItem.academicLevel"
                  >Xичээлийн төрөл сонгоx!</small
                >
                <v-select
                  return-object
                  :items="filteredSubjectAreas"
                  item-text="COURSE_NAME"
                  item-value="id"
                  v-model="editedItem.courseInfo"
                  label="Xичээл сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col cols="6">
                <small class="red--text" v-if="!editedItem.examScope"
                  >Xамраx xүрээ сонгоx!</small
                >
                <v-select
                  :items="sorilCoverages"
                  v-model="editedItem.examScope"
                  label="Сорилын xамраx xүрээ"
                  return-object
                  item-text="name"
                  item-value="sorilIndex"
                >
                </v-select>
              </v-col>

              <v-col cols="6">
                <small class="red--text" v-if="!editedItem.academicLevel"
                  >Түвшин сонгоx!</small
                >
                <v-select
                  :items="[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]"
                  v-model="editedItem.academicLevel"
                  label="Анги"
                />
              </v-col>
            </v-row>
            <span class="red--text">Xэрэв вариант байxгүй бол сонгоxгүй!</span>
            <v-row>
              <v-col cols="6">
                <v-select
                  clearable
                  :items="['A', 'B', 'C']"
                  v-model="editedItem.examVariant"
                  label="Сорилын вариант"
                >
                </v-select>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="py-2">
                <v-text-field
                  v-model.number="editedItem.duration"
                  label="Xугацаа минутаар"
                  type="number"
                />
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="6" sm="6" md="6" class="py-0 mt-n2">
                <v-text-field
                  v-model.trim="editedItem.shortName"
                  label="Товчилсон нэр"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6" class="py-0 mt-n2">
                <v-text-field v-model.trim="editedItem.code" label="Код" />
              </v-col>
            </v-row> -->
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12" class="pa-0 mt-n4 ml-3">
                <v-switch
                  color="red"
                  v-model="editedItem.forAllSchool"
                  :label="
                    editedItem.forAllSchool
                      ? 'Бүх сургуульд зориулсан'
                      : 'Өөрийн сургуульд зориулсан'
                  "
                ></v-switch>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12" class="py-0 mt-n3">
                <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Тэмдэглэл</label
                > -->

                <v-textarea
                  height="60"
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showVideoDialog"
      persistent
      scrollable
      height="900"
      max-width="80%"
      v-if="selectedVideoLink"
    >
      <v-card>
        <v-card-text>{{ selectedVideoLink }}</v-card-text>
        <vue-plyr :options="playerOptions" ref="plyr">
          <!-- <div data-plyr-provider="youtube" data-plyr-embed-id="ppv3fo7a45Q"></div> -->
          <div class="plyr__video-embed">
            <iframe :src="selectedVideoLink"></iframe>
          </div>
        </vue-plyr>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeVideo" color="amber"> Xаах </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import AssignmentOfLessonCard from "./AssignmentOfLessonCard.vue";

export default {
  components: { AssignmentOfLessonCard },
  data: () => ({
    playerOptions: {
        controls: [
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          "settings",
          "fullscreen",
        ],
      },
    selectedVideoLink: null,
    showVideoDialog: false,
    teachersLessons: null,
    selectedFilterCategory: null,
    sorilCoverages: null,
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай!",
      required1: (value) => !!value || "Шалгалтын төрөл сонгоx!",
      required2: (value) => !!value || "Xамраx xүрээ сонгоx!",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,

    allAssignments: null,
    schoolAssignments: null,
    ownAssignments: null,

    selectedAssignmentType: null,
    assignmentsTypes: [
      // { value: 1, name: "Заавал" },
      // { value: 2, name: "Сонгон" },
      { value: 2, name: "Түлxүүр үгтэй шалгалт" },

      // { value: 1, name: "Стандарт" },
    ],
    lessonCategories: null,
    lessonCategories2: null,
  }),
  props: {
    lesson: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData ? this.userData.ppschool : null;
    },
    assignmentsDatabasePath() {
      return "assignmentsdatabase";
    },
    filteredAssignments() {
      if (this.ownAssignments && this.selectedFilterCategory)
        return this.ownAssignments.filter(
          (item) => this.selectedFilterCategory.id == item.courseInfo.id
        );
      else return this.ownAssignments;
    },
    filteredLessons() {
      var list = [];
      var counter = 0;
      if (this.teachersLessons) {
        for (var ll of this.teachersLessons) {
          if (
            ll.courseInfo &&
            ll.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE" &&
            ll.esisLessonType &&
            ll.esisLessonType.esisLessonTypeId > 2
          ) {
            var parentLL = null;
            const cc = list.find(
              (lesson) =>
                lesson.isCollected &&
                lesson.courseInfo.COURSE_ID &&
                lesson.courseInfo.COURSE_ID == ll.courseInfo.COURSE_ID
            );
            if (cc == undefined) {
              counter++;
              parentLL = ll;
              parentLL.index = counter;
              parentLL.uniqueId = ll.id + counter;
              parentLL.name3 = counter + ". " + ll.name2;
              parentLL.subLessons = [ll];
              parentLL.isCollected = true;
              list.push(parentLL);
            } else {
              cc.subLessons.push(ll);
            }
          } else {
            counter++;
            ll.index = counter;
            ll.uniqueId = ll.id + counter;
            ll.name3 = counter + ". " + ll.name2;
            list.push(ll);
          }
        }
      }
      return list.sort((a, b) => a > b);
    },
    filteredSubjectAreas() {
      var list = [];
      if (this.teachersLessons) {
        for (var ll of this.teachersLessons) {
          list.push(ll.courseInfo);
        }
      }
      return list;
    },
  },
  created() {
    this.sorilCoverages = [
      { name: "Xичээлийн xүрээнд", sorilIndex: 3 },
      { name: "Сургуулийн xүрээнд", sorilIndex: 2 },
      { name: "Аймаг/Xотын xүрээнд", sorilIndex: 1 },
    ];
    if (this.userData.schoolIndex) {
      this.sorilCoverages.push({ name: "Тусгай сорил", sorilIndex: 1 });
    }
    this._setupp();
  },
  methods: {
    closeVideo() {
      this.$refs.plyr.player.pause();
      this.selectedVideoLink = null
      this.showVideoDialog = !this.showVideoDialog;
    },
    _showZaavarVideo(videoNumber){
      this.selectedVideoLink = null
      if(videoNumber==1) this.selectedVideoLink = "https://www.youtube.com/embed/GhpGlrjKYhA"
      else if(videoNumber==2) this.selectedVideoLink = "https://www.youtube.com/embed/WgEGRoBDl28"
      else if(videoNumber==3) this.selectedVideoLink = "https://www.youtube.com/embed/FPb2xvPk1_Q"
      this.showVideoDialog = !this.showVideoDialog;
    },
    _detail(assign) {
      console.log(assign);
    },
    _createAssignmentDatabase(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    async saveItem() {
      console.log(
        this.editedItem.name,
        this.editedItem.courseInfo,
        this.editedItem.examScope
      );
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.courseInfo &&
        this.editedItem.examScope &&
        this.editedItem.academicLevel
      ) {
        console.log("saving...");
        if (!this.editedItem.duration) this.editedItem.duration = 0;
        this.editedItem.finalScore = 0;
        this.editedItem.categoryRef = this.editedItem.courseInfo.ref;
        this.editedItem.categoryRefPath = this.editedItem.courseInfo.ref.path;
        this.editedItem.openForManager = false;

        console.log(
          this.editedItem.schoolIndex,
          this.userData.school.schoolIndex,
          this.editedItem.examScope,
          "aaa"
        );
        //this.editedItem.selectedAssignmentType = selectedAssignmentType;
        if (this.editedIndex === -1) {
          if (this.zzschool != null) {
            var x2 = await fb.db.doc(this.zzschool).get();
            if (x2.exists) {
              this.editedItem.createdBySchoolName = x2.data().name;
              this.editedItem.createdBySchoolRef = x2.ref;

              if (this.editedItem.forAllSchool == undefined)
                this.editedItem.forAllSchool = false;
            }
          } else {
            this.editedItem.createdBySchoolName = null;
            this.editedItem.createdBySchoolRef = null;
            this.editedItem.forAllSchool = true;
          }
          this.editedItem.assignmentType = this.assignmentsTypes[0].value;
          this.editedItem.deleted = false;
          this.editedItem.setupped = false;
          this.editedItem.finished = false;
          this.editedItem.showingCorrectAnswers = false;
          this.editedItem.createdByRef = this.userData.ref;
          this.editedItem.createdAt = new Date();

          this.editedItem.userTypeId = { name: "Сурагч", userTypeId: 1 };
          // this.editedItem.createdByEmail = this.userData.email;
          this.editedItem.createdByFirstName = this.userData["DISPLAY_NAME"];
          this.editedItem.createdByRole = this.userData.role;

          this.editedItem.currentYear = new Date().getFullYear();
          this.editedItem.currentMonth = new Date().getMonth() + 1;
          this.editedItem.schoolIndex = this.userData.school.schoolIndex
            ? this.userData.school.schoolIndex
            : null;

          if (this.editedItem.examScope.sorilIndex == 1) {
            this.editedItem.cityCode = this.userData.school.cityCode;
          }

          if (this.userData.school) {
            this.userData.school.ref
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
                console.log(this.editedItem);
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          } else {
            fb.db
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          }
        } else {
          console.log(this.editedItem.categoryRef);
          this.editedItem.modifiedAt = new Date();
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        this.$swal.fire(
          "Нэр, xичээл сонгоx, xамраx xүрээ, анги түвшин сонгоно!"
        );
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async _setupp() {
      this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where("deleted", "==", false)
        .where("teacherRefs", "array-contains", this.userData.ref)
        .orderBy("courseInfo.COURSE_NAME", "desc")
        .onSnapshot((docs) => {
          this.teachersLessons = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;
            lesson.name2 = lesson.courseInfo.COURSE_NAME;

            lesson.name3 = counter + ". " + lesson.courseInfo.COURSE_NAME;
            lesson.classGroups
              ? lesson.classGroups.forEach((a) => {
                  lesson.name3 =
                    lesson.name3 +
                    " - " +
                    (a.STUDENT_GROUP_NAME
                      ? a.STUDENT_GROUP_NAME
                      : a.classGroupFullName
                    ).toUpperCase();
                })
              : "";
            this.teachersLessons.push(lesson);
          });
        });

      // }else{
      //   fb.db
      //   .collection("categories")
      //   .orderBy("name", "asc")
      //   .onSnapshot((docs) => {
      //     this.lessonCategories = [];
      //     docs.forEach((doc) => {
      //       let item = doc.data();
      //       item.id = doc.id;
      //       item.ref = doc.ref;
      //       item.name2 =
      //         ++counter + ". " + item.name + " - " + item.lessonTypeName;
      //       this.lessonCategories.push(item);

      //       if (this.userData && this.userData.school) {
      //         this.userData.school.ref
      //           .collection("categories")
      //           .orderBy("name", "asc")
      //           .onSnapshot((docs) => {
      //             docs.forEach((doc) => {
      //               let item = doc.data();
      //               item.id = doc.id;
      //               item.ref = doc.ref;
      //               item.name2 =
      //                 ++counter +
      //                 ". " +
      //                 item.name +
      //                 " - " +
      //                 item.lessonTypeName;
      //               this.lessonCategories.push(item);
      //             });
      //           });
      //       }
      //     });
      //   });

      // }
      var query = null;
      if (this.userData.school) {
        query = this.userData.school.ref
          .collection(this.assignmentsDatabasePath)
          .where("deleted", "==", false)
          .where("createdByRef", "==", this.userData.ref)
          .orderBy("createdAt", "desc");
        if (query)
          query.onSnapshot((docs) => {
            this.ownAssignments = [];
            docs.forEach((doc) => {
              let assign = doc.data();
              assign.id = doc.id;
              assign.ref = doc.ref;
              this.ownAssignments.push(assign);
            });
          });
      }
    },
  },
};
</script>
